import { put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { FILTER_PRODUCTS, filterError, filterProducts, filterSuccess } from './actions';
import { selectProductsLoading } from './selectors';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

const types = {
  'Samplers': 'Sampler',
  'Boxes': 'Box',
  'Bundles': 'Pack',
  '5 Packs': '5-Pack',
  'Singles': 'Single',
};

function* filter(action) {
  const { options, page } = action;

  // const isLoading = yield select(selectProductsLoading());
  // if (isLoading) {
  //   console.log('Already loading shop products...');
  //   return;
  // }

  yield put(filterProducts(page, options));

  const limit = 30;
  let query = `limit=${limit}&page=${page || 1}`;

  if (options.origin) {
    query += `&origin=${options.origin}`;
  }

  if (options.wrapper) {
    query += `&wrapper=${options.wrapper}`;
  }

  if (options.strength) {
    query += `&strength=${options.strength}`;
  }

  if (options.price_min) {
    query += `&price_min=${options.price_min}`;
  }

  if (options.price_max) {
    query += `&price_max=${options.price_max}`;
  }

  if (options.shape) {
    query += `&shape=${options.shape}`;
  }

  if (options.length_min) {
    query += `&length_min=${options.length_min}`;
  }

  if (options.length_max) {
    query += `&length_max=${options.length_max}`;
  }

  if (options.gauge_min) {
    query += `&gauge_min=${options.gauge_min}`;
  }

  if (options.gauge_max) {
    query += `&gauge_max=${options.gauge_max}`;
  }

  if (options.type) {
    query += `&package_type=${types[options.type]}`;
  }

  if (options.venue_id) {
    query += `&venue_id=${options.venue_id}`;
  }

  // TODO Add sort order to the filter options
  console.log('Getting all products in saga:');
  console.log(`${config.shopEndPoint}/products?${query}`);

  const result = yield axios.get(`${config.shopEndPoint}/products?${query}`);

  console.log(`Querying url: ${config.shopEndPoint}/products?${query}`);

  if (result.status === 200) {
    console.log('Products:');
    console.log(result.data);
    yield put(filterSuccess({
      currentPage: page,
      totalPages: 10, // FIXME Can this be returned by the server?
      hasMore: result.data.length > 0 && result.data.length === limit,
    },
    result.data,));
  } else {
    yield put(filterError({
      success: false,
      message: 'Unable to get products',
      hasMore: true,
    }));
  }
}

export function* filterProductsWatcher() {
  yield takeLatest(FILTER_PRODUCTS.REQUEST, filter);
}

export default [
  filterProductsWatcher,
];
