import { fromJS, List } from 'immutable';
import { FILTER_DEALS } from './actions';

const filterState = fromJS({
  currentPage: 0,
  totalPages: 999,
  loading: false,
  hasMore: true,
  errorMessage: '',
  products: List(),
});
export function filterProductsReducer(state = filterState, action) {
  switch (action.type) {
    case FILTER_DEALS.FETCH: {
      return state
        .set('loading', true)
        .set('currentPage', action.page)
        .set('errorMessage', '');
    }

    case FILTER_DEALS.SUCCESS: {
      const { paging, products } = action;
      if (paging.currentPage === 1) {
        return state
          .set('totalPages', parseInt(paging.totalPages, 10))
          .set('loading', false)
          .set('hasMore', paging.hasMore)
          .set('products', List(products));
      } else {
        return state
          .set('totalPages', parseInt(paging.totalPages, 10))
          .set('loading', false)
          .set('hasMore', paging.hasMore)
          .update('products', (list) => list.push(...products));
      }
    }

    case FILTER_DEALS.FAILURE: {
      return state
        .set('loading', false)
        .set('errorMessage', action.error.message);
    }

    default:
      return state;
  }
}

export default function productMappingReducer(state = filterState, action) {
  switch (action.type) {
    case FILTER_DEALS.FETCH:
    case FILTER_DEALS.SUCCESS:
    case FILTER_DEALS.FAILURE: {
      const selectedList = state.get('filtered_deals');
      const updatedProductState = filterProductsReducer(selectedList, action);
      return state.set('filtered_deals', updatedProductState);
    }

    default:
      return state;
  }
}
