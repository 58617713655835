import { createAsyncAction } from '../../utils/actionUtils';

export const FILTER_DEALS = createAsyncAction('FILTER_DEALS');

export function requestProducts(page, options = {}) {
  console.log('Deals request details');
  console.log(page);
  console.log(options);
  return {
    type: FILTER_DEALS.REQUEST,
    page,
    options,
  };
}

export function filterProducts(page, options = {}) {
  return {
    type: FILTER_DEALS.FETCH,
    page,
    options,
  };
}

export function filterSuccess(paging, products) {
  return {
    type: FILTER_DEALS.SUCCESS,
    paging,
    products,
  };
}

export function filterError(error) {
  return {
    type: FILTER_DEALS.FAILURE,
    error,
  };
}
