import { createSelector } from 'reselect';
import { fromJS, List } from 'immutable';

export const selectProductMapping = () => (state) => state.getIn(['global', 'products', 'all']);
export const selectProductRequest = () => createSelector(
  selectProductMapping(),
  (productState) => productState.get('filtered_products', fromJS({
    loading: false,
    errorMessage: '',
    products: List(),
  }))
);

export const selectProductsLoading = () => createSelector(
  selectProductRequest(),
  (productState) => productState.get('loading'),
);

export const selectProductsCurrentPage = () => createSelector(
  selectProductRequest(),
  (productState) => productState.get('currentPage'),
);

export const selectProductsTotalPage = () => createSelector(
  selectProductRequest(),
  (productState) => productState.get('totalPages'),
);

export const selectProductsHasMore = () => createSelector(
  selectProductRequest(),
  (productState) => productState.get('hasMore'),
);

export const selectProductErrorMessage = () => createSelector(
  selectProductRequest(),
  (productState) => productState.get('errorMessage'),
);

export const selectProducts = () => createSelector(
  selectProductRequest(),
  (productState) => productState.get('products'),
);

export const selectProductCategory = (categoryTitle) => createSelector(
  selectProducts(),
  (categories) => {
    categories.toJS().forEach((category) => {
      if (category.title === categoryTitle) {
        return category;
      }
    });
    return [];
  },
);
