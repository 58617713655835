import { put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { FILTER_DEALS, filterError, filterProducts, filterSuccess } from './actions';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

const types = {
  'Samplers': 'Sampler',
  'Boxes': 'Box',
  'Bundles': 'Pack',
  '5 Packs': '5-Pack',
  'Singles': 'Single',
};

function* filter(action) {
  alert(`Getting products`);
  // FIXME Deals need a sale price and should default to sorting by largest discount (discount amount should be difference between MSRP and sale price, not regular price)
  const { options, page } = action;

  // const isLoading = yield select(selectProductsLoading());
  // if (isLoading) {
  //   return;
  // }

  yield put(filterProducts(page, options));

  const limit = 30;
  let query = `limit=${limit}&page=${page || 1}`;

  if (options.origin) {
    query += `&origin=${options.origin}`;
  }

  if (options.wrapper) {
    query += `&wrapper=${options.wrapper}`;
  }

  if (options.strength) {
    query += `&strength=${options.strength}`;
  }

  if (options.price_min) {
    query += `&price_min=${options.price_min}`;
  }

  if (options.price_max) {
    query += `&price_max=${options.price_max}`;
  }

  if (options.type) {
    query += `&package_type=${types[options.type]}`;
  }

  // TODO Add sort order to the filter options

  const result = yield axios.get(`${config.shopEndPoint}/products?${query}`);

  console.log(`Querying url: ${config.shopEndPoint}/products?${query}`);

  if (result.status === 200) {
    // console.log('Products:');
    // console.log(result.data);
    yield put(filterSuccess({
      currentPage: page,
      totalPages: 10, // FIXME Can this be returned by the server?
      hasMore: result.data.length > 0 && result.data.length === limit,
    },
    result.data));
  } else {
    yield put(filterError({
      success: false,
      message: 'Unable to get products',
    }));
  }
}

export function* filterDealsWatcher() {
  yield takeLatest(FILTER_DEALS.REQUEST, filter);
}

export default [
  filterDealsWatcher,
];
