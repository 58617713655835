import { createAsyncAction } from '../../utils/actionUtils';

export const FILTER_PRODUCTS = createAsyncAction('FILTER_PRODUCTS');

export const filterOptions = {
  sort: 'relevant',
  type: null, // Package type
  origin: null,
  wrapper: null,
  // rating: 3.5, // INFO I think by default, there should be no restrictions. Maybe pick this based
  // price_min: 35,
  // price_max: 125,
};

export function requestProducts(page, options = filterOptions) {
  console.log('Product request details');
  console.log(page);
  console.log(options);
  return {
    type: FILTER_PRODUCTS.REQUEST,
    page,
    options,
  };
}

export function filterProducts(page, options = {}) {
  return {
    type: FILTER_PRODUCTS.FETCH,
    page,
    options,
  };
}

export function filterSuccess(paging, products) {
  return {
    type: FILTER_PRODUCTS.SUCCESS,
    paging,
    products,
  };
}

export function filterError(error) {
  return {
    type: FILTER_PRODUCTS.FAILURE,
    error,
  };
}
